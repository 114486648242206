<template>
  <b-container fluid>
    <bo-page-title />
    <div class="dashboard">
      <div class="card mb-3">
        <div class="card-body">
          <b-row align-v="center">
            <b-col lg="auto">
              <h5 class="card-title mb-0">Data Period</h5>
            </b-col>
            <b-col lg="3">
              <date-picker range value-type="format" prefix-class="crm" v-model="dateFilter" v-b-tooltip.hover="'Filter by Date'" format="YYYY-MM-DD" @input="doFilterDate" :disabled-date="notAfterToday" />
            </b-col>
          </b-row>
        </div>
      </div>
      <b-card class="finance-card mb-3">
        <b-row align-v="center" class="gutter-2">
          <b-col md="5">
            <div class="wallet-card">
              <div class="wc-icon bg_card_5">
                <i class="icon-wallet"></i>
              </div>
              <div class="wc-content">
                <span>Cash in Bank</span>
                <h4>{{ formatRupiah(report.cash_in_bank) }}</h4>
              </div>
              <b-button variant="transparent" size="sm" class="btn-rounded text-center" @click="openModalCashInBank">
                <i v-b-tooltip.hover.noninteractive="'Add Cash In Bank'" class="fa fa-plus-circle " />
              </b-button>
              <i class="icon-angle-right"></i>
            </div>
          </b-col>
          <b-col md="7">
            <b-row class="gutter-2">
              <b-col md="6">
                <div class="wallet-card wallet-card--sm" role="button" @click.prevent="openModal('Revenue')" v-b-tooltip.hover="'Click to view details'">
                  <div class="wc-icon bg_card_2">
                    <i class="icon-wallet"></i>
                  </div>
                  <div class="wc-content">
                    <span>Revenue</span>
                    <h4>{{ formatRupiah(report.revenue) }}</h4>
                  </div>
                </div>
              </b-col>
              <b-col md="6">
                <div class="wallet-card wallet-card--sm" role="button" @click.prevent="openModal('Expenses')" v-b-tooltip.hover="'Click to view details'">
                  <div class="wc-icon bg_card_1">
                    <i class="icon-wallet"></i>
                  </div>
                  <div class="wc-content">
                    <span>Expenses</span>
                    <h4>{{ formatRupiah(report.expenses) }}</h4>
                  </div>
                </div>
              </b-col>
              <b-col md="6">
                <div class="wallet-card wallet-card--sm" role="button" @click.prevent="openModal('Account Receivable')" v-b-tooltip.hover="'Click to view details'">
                  <div class="wc-icon bg_card_3">
                    <i class="icon-wallet"></i>
                  </div>
                  <div class="wc-content">
                    <span>Account Receivable</span>
                    <h4>{{ formatRupiah(report.accounts_receivable) }}</h4>
                  </div>
                </div>
              </b-col>
              <b-col md="6">
                <div class="wallet-card wallet-card--sm" role="button" @click.prevent="openModal('Omzet')" v-b-tooltip.hover="'Click to view details'">
                  <div class="wc-icon bg_card_4">
                    <i class="icon-wallet"></i>
                  </div>
                  <div class="wc-content">
                    <span>Omzet</span>
                    <h4>{{ formatRupiah(report.omzet) }}</h4>
                  </div>
                </div>
              </b-col>
              <b-col md="6">
                <div class="wallet-card wallet-card--sm" role="button" @click.prevent="openModal('Bailout')" v-b-tooltip.hover="'Click to view details'">
                  <div class="wc-icon bg_card_4">
                    <i class="icon-wallet"></i>
                  </div>
                  <div class="wc-content">
                    <span>Bailout</span>
                    <h4>{{ formatRupiah(report.bailout) }}</h4>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-modal id="detailFR" size="lg" hide-footer :title="dataModal.title || ''">
          <b-table-simple bordered striped v-if="dataModal.title != 'Expenses'">
            <b-thead>
              <b-tr>
                <b-th>No.</b-th>
                <b-th>Project</b-th>
                <b-th v-if="dataModal.title != 'Account Receivable'">Date</b-th>
                <b-th>Amount</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <template v-for="(value, index) in dataModal.data">
                <b-tr :key="'A' + index">
                  <b-td>{{ index + 1 }}</b-td>
                  <b-td>{{ value.client_name }}</b-td>
                  <b-td v-if="value.date">{{ value.date | moment('MMM D') }}, {{ value.date | moment('YYYY') }}</b-td>
                  <b-td>{{ formatRupiah(value.value) }}</b-td>
                </b-tr>
              </template>

              <b-tr v-if="length(dataModal.data) < 1">
                <b-td colspan="5" v-if="isLoading">
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle mr-3 text-info"></b-spinner>
                    <strong class="text-info">Loading...</strong>
                  </div>
                </b-td>
                <b-td colspan="5" v-else>
                  <div class="text-center">
                    <b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
                    <h4 align="center"><span>No {{ dataModal.title }} data listed yet</span></h4>
                  </div>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>

          <b-table-simple bordered striped v-else>
            <b-thead>
              <b-tr>              
                <b-th>Operational</b-th>
                <b-th>Non Operational</b-th>              
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-if="!isLoading">              
                <b-td>{{ formatRupiah(dataModal.data.operational) }}</b-td>
                <b-td>{{ formatRupiah(dataModal.data.non_operational) }}</b-td>
              </b-tr>

              <b-tr v-else>
                <b-td colspan="5">
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle mr-3 text-info"></b-spinner>
                    <strong class="text-info">Loading...</strong>
                  </div>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-modal>
      </b-card>
      <b-card class="mt-3">
        <b-card-header>
          <b-row align-v="center">
            <b-col lg="auto">
              <h5 class="card-title mb-0">Cashflow</h5>
            </b-col>
            <b-col lg="3">
              <v-select id="yearg" v-model="filter.yearg" :options="dataYear" @input="doFilter()" :reduce="v => v.label" label="label"/>
            </b-col>
          </b-row>
        </b-card-header>
        <v-chart :option="cashflowChart" class="chart" />
      </b-card>

      <b-modal
        id="addCashInBank"
        :title="'Add Cash In Bank'"
        centered
        no-close-on-backdrop
      >
        <validation-observer v-slot="{ handleSubmit }" ref="VFormCIB">
          <b-form @submit.prevent="handleSubmit(addExpense())">
            <b-form-group label-for="type">
              <template #label>Type <strong class="text-danger">*</strong></template>
              <v-select id="type" v-model="dataModalCib.aioc_type" :options="inOutOption" :reduce="v => v.value" label="label"/>
              <VValidate name="Type" :rules="{required: true}" v-model="dataModalCib.aioc_type" />
            </b-form-group>
            <b-form-group>
              <template #label>Note <strong class="text-danger">*</strong></template>
              <b-form-textarea v-model="dataModalCib.aioc_note" />
              <VValidate name="Item" :rules="{required: true, min: 2, max: 128}" v-model="dataModalCib.aioc_note" />
            </b-form-group>
            <template v-if="dataModalCib.aioc_type == 'exp'">
              <b-form-group>
                <template #label>Receiver <strong class="text-danger">*</strong></template>
                <b-form-input v-model="dataModalCib.aioc_receiver" />
                <VValidate name="Receiver" :rules="{required: true}" v-model="dataModalCib.aioc_receiver" />
              </b-form-group>
               <b-form-group label-for="credit">
                <template #label>Credit <strong class="text-danger">*</strong></template>
                <b-input-group prepend="Rp.">
                  <money id="credit" v-model="dataModalCib.aioc_credit" v-bind="maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                </b-input-group>
                <VValidate name="Credit" :rules="{required: true}" v-model="dataModalCib.aioc_credit" />
              </b-form-group>
            </template>
            <template v-else-if="dataModalCib.aioc_type == 'inc'">
              <b-form-group label-for="debit">
                <template #label>Debit <strong class="text-danger">*</strong></template>
                <b-input-group prepend="Rp.">
                  <money id="debit" v-model="dataModalCib.aioc_debit" v-bind="maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                </b-input-group>
                <VValidate name="Debit" :rules="{required: true}" v-model="dataModalCib.aioc_debit" />
              </b-form-group>
            </template>
            <b-form-group label-for="date">
              <template #label>Date <strong class="text-danger">*</strong></template>
              <date-picker v-model="dataModalCib.aioc_date" value-type="format" format="YYYY-MM-DD" prefix-class="crm" :disabled-date="notAfterToday" />
              <VValidate name="Date" :rules="{required: true}" v-model="dataModalCib.aioc_date" />
            </b-form-group>           
          </b-form>
        </validation-observer>
        <template #modal-footer>
          <b-button size="md" variant="transparent" @click="closeModalCashInBank">Cancel</b-button>
          <b-button size="md" variant="info" @click="submitCashInBank()">Submit</b-button>
        </template>
      </b-modal>
    </div>
  </b-container>
</template>
<style lang="scss" scoped>
  .chart{
    height: 480px;
  }
</style>
<script>
import GlobalVue from '../../../libs/Global.vue'
import Gen from '@/libs/Gen'
import { use } from 'echarts/core'
import { SVGRenderer } from 'echarts/renderers'
import { LineChart, PieChart } from 'echarts/charts'
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from 'echarts/components'
import VChart from 'vue-echarts'
import DatePicker from 'vue2-datepicker'

use([
  SVGRenderer,
  LineChart,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
])

export default {
  extends: GlobalVue,
  components: {
    VChart,
    DatePicker,
  },
  data() {
    return {
      series: {},
      dataYear: [],
      dataModal: { title: '' },
      report: {},
      dateFilter: [],
      isLoading: false,
      inOutOption: [
        {label: 'INC', value: 'inc'},
        {label: 'EXP', value: 'exp'},
      ],
      dataModalCib: {}
    }
  },
  methods: {
    openModalCashInBank(){
      this.$bvModal.show('addCashInBank')
    },
    closeModalCashInBank(){
      this.$bvModal.hide('addCashInBank')
      this.dataModalCib = {}
    },
    submitCashInBank(){
      this.$refs.VFormCIB.validate().then(success => {
        if(!success) return

        this.$swal({
          title: 'Submit Cash In Bank...',
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading()
          }
        })

        Gen.apiRest('/do/' + this.modulePage,
        { data: {type: 'add-cib', ...this.dataModalCib} }, 'POST'
        )
        .then( res => {
          if(res){
            this.$swal({
              title: res.data.message,
              icon: 'success'
            }).then( () => {
              this.dataModalCib = {}
              this.$bvModal.hide('addCashInBank')
              this.apiGet()
            })
          }
        })
        .catch( () => {
          this.$swal({
            title: 'Terjadi suatu kesalahan!',
            text: 'Silakan coba lagi',
            icon: 'error'
          }).then(() => {
            this.dataModalCib = {}
            this.$bvModal.hide('addCashInBank')
          })
        } )
      })
    },
    notAfterToday(date){
      return date > new Date()
    },
    doFilterDate(newVal){
      this.$set(this.filter, 'start-date', newVal[0] || '')
      this.$set(this.filter, 'end-date', newVal[1] || '')
      this.doFilter()
    },
    openModal(frType){
      const type = frType.split(" ").map(x => x.charAt(0)).join("")

      this.$set(this, 'dataModal', {'data': [], 'title': frType})
      this.$set(this, 'isLoading', true)

      Gen.apiRest(
        '/do/' + this.modulePage,
        {data:{ type: "detail", fr_type: type, start_date: this.$route.query['start-date'] || null, end_date: this.$route.query['end-date'] || null }},
        "POST"
      ).then(res => {
        setTimeout(() => {           
          this.$set(this, 'dataModal', { data: res.data, title: frType })
          this.$set(this, 'isLoading', false)
        }, 400)
      })
      this.$bvModal.show("detailFR")
    }
  },
  computed: {
    cashflowChart(){
      return {
        color: ['#0076BB', '#F03A47', '#50C5B7', '#0076BB', '#9CEC5B', '#F0F465', '#FF8600'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: data => {
            let text = ''
            if(data && data[0]){
              text = `
                <div class="mb-2"><strong>${data[0] ? data[0].name+' '+this.filter.yearg : ''} </strong></div>
                <div class="tooltip-content">
              `

              text += `
                <div class="d-flex mb-1">
                  <div>
                    ${data[0] && data[0].marker}
                  </div>
                  <div>
                    ${data[0] && data[0].seriesName}
                    <strong class="text-right d-block">
                      ${this.formatRupiah(data[0] && data[0].data)}
                    </strong>
                  </div>
                </div>
              `
            }

            if(data && data[1]){
              text += `
                <div class="d-flex">
                  <div>
                    ${data[1] && data[1].marker}
                  </div>
                  <div>
                    ${data[1] && data[1].seriesName}
                    <strong class="text-right d-block">                      
                      ${this.formatRupiah(data[1] && data[1].data)}
                    </strong>
                  </div>
                </div>
              `
            }

            if(data && data[2]){
              text += `
                <div class="d-flex">
                  <div>
                    ${data[2] && data[2].marker}
                  </div>
                  <div>
                    ${data[2] && data[2].seriesName}
                    <strong class="text-right d-block">                      
                      ${this.formatRupiah(data[2] && data[2].data)}
                    </strong>
                  </div>
                </div>
              `
            }
            text += '</div>'
            return text          
          }
        },
        legend: {
          data: ['Revenue', 'Expenses', 'Income']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            data: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
          }
        ],
        yAxis: [
          {
            type: 'value',
          }
        ],
        series: this.series
      }
    } 
  },
  mounted(){
    this.apiGet()

    const a = []
    a[0] = this.$route.query['start-date'] || null
    a[1] = this.$route.query['end-date'] || null
    this.filter.yearg = this.$route.query['yearg'] || new Date().getFullYear()

    this.$set(this, 'dateFilter', a)
  },
  watch: {
    $route(){
      this.apiGet()
    },
    'filter'(){
      const a = []
      a[0] = this.filter['start-date'] || null
      a[1] = this.filter['end-date'] || null
      this.$set(this, 'dateFilter', a)
    }
  }

}
</script>